// import React from 'react'
// import rehypeReact from 'rehype-react'
// import MyAdSense from '../adsense';

// const renderAst = new rehypeReact({
//   createElement: React.createElement,
//   components: {
//     'adsense': MyAdSense,
//   },
// }).Compiler;

// export const PostContainer = ({ htmlAst }) => (
//   <div>{renderAst(htmlAst)}</div>
// )

import React from 'react'

export const PostContainer = ({ html }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} />
)
